<template>
  <div class="kt-section" id="current-events-list">
    <template v-for="(event, i) in events">
      <EventListRow
        v-bind:key="i"
        :event="event.event"
        :member_event="event.member_event"
        @selectEventClicked="selectEventClicked"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";
</style>

<script>
import axios from 'axios';
import EventListRow from '@/view/pages/members/public/EventListRow.vue';
import messageParent from '@/core/services/messageParent';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'events-list',
  props: ['events'],
  emits: ['selectEventClicked'],
  components: {
    EventListRow
  },
  watch: {
  },
  mixins: [ toasts ],
  mounted() {
    setTimeout(function(){
      messageParent(JSON.stringify({ height: document.body.scrollHeight }));
    }, 300);
  },
  methods: {
    selectEventClicked(event, member_event) {
      this.$emit('selectEventClicked', event, member_event);
    },

  },
  data() {
    return {
      list: []
    };
  }
};
</script>
